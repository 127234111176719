<script setup lang="ts">
import { computed, PropType } from 'vue'
import { StopChip } from '~/types'
const props = defineProps({
  spacing: {
    type: Boolean,
    default: false,
  },
  stop: {
    type: Object as PropType<StopChip>,
    required: true,
  },
})

const isNightBus = () => {
  return props.stop.name.startsWith('N')
} 
const formatStopColor = computed(() => {
  if(isNightBus()) {
    return 'black'
  }
  return `#${props.stop.colour}`
})
const formatStopTextColor = computed(() => {
  if(isNightBus()) {
    return '#F7E819'
  }
  return `white`
})
</script>
<template>
  <span
    class="stop-chip py-1 px-2 min-w-[46px] text-white font-medium leading-none text-center"
    :class="{
      'mx-1 my-1': !!spacing,
      'rounded-full': !isNightBus(),
    }"
    :style="{
      backgroundColor: formatStopColor,
      color: formatStopTextColor,
    }"
  >
    {{ stop.name }}
  </span>
</template>
